const links = [
    { name: 'See Projects', href: '/projects' },
    { name: 'Contact Me', href: '/contact' },
    { name: 'Resume', href: '/contact' },
]
const stats = [
    { name: 'MERN Bootcamp Students Assisted', value: '500+' },
    { name: '5 Star Reviews', value: '100+' },
    { name: 'Assignments Debugged', value: '500+' },
    { name: 'Projects', value: '10+' },
]
  
  export default function About() {
    return (
      <div className="relative isolate overflow-hidden lg:h-screen bg-gray-900 py-24 sm:py-32">
        <img
          src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
        //   https://images.unsplash.com/photo-1607798748738-b15c40d33d57?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        />
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Dominick Simone</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
            Hello, my name is Dominick Simone. I am a Full Stack Developer from Lewisburg, PA. I graduated from the Full-Stack Coding Bootcamp at the University of Pennsylvania. I am currently working as a Senior Learning Assistant for Full Stack Development Bootcamps at edX. I am looking for a full time software development position!
            </p>
          </div>
          <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
              {links.map((link) => (
                <a key={link.name} href={link.href}>
                  {link.name} <span aria-hidden="true">&rarr;</span>
                </a>
              ))}
            </div>
            <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
              {stats.map((stat) => (
                <div key={stat.name} className="flex flex-col-reverse">
                  <dt className="text-base leading-7 text-gray-300">{stat.name}</dt>
                  <dd className="text-2xl font-bold leading-9 tracking-tight text-white">{stat.value}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    )
  }
  